.background-form-login-rut {
  display: block;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: white;
  padding: 0 10px;
  min-height: 100vh;
}

.login-container {
  width: 90%;
  height: 90%;
  margin: 20%;
}

.loading {
  animation: fa-spin 1.5s linear infinite;
  font-size: 2rem;
  margin-top: 15%;
  margin-left: 50%;
}

.container {
  padding: 2px 16px;
}

.titulo-centro-login-rut {
  height: 4.69%;
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 3em;
  display: block;
}

span {
  color: #4236ff;
  font-family: "Nunito Sans";
}

.field-login-rut #input-for-rut {
  box-sizing: border-box;
  height: 40px;
  width: 285px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  margin-bottom: 25px !important;
}

.field-login-rut {
  height: 88px;
  width: 285px;
  display: block;
  margin-bottom: 20px !important;
}

.field-login-rut .rut-label {
  display: block;
  height: 2.34%;
  width: 7.2%;
  color: #4a4a4a;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  float: left;
  margin-right: 80.8%;
  margin-left: 0%;
  text-align: left;
  margin-bottom: 20px;
}

.titulo-login-rut {
  height: 4.19%;
  width: max-content;
  margin-bottom: 2rem;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-top: 0% !important;
}

#button-continue-login-rut {
  height: 45px !important;
  width: 285px !important;
  background-color: #4236ff !important;
  border-radius: 8px !important;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

#button-continue-login-rut:disabled {
  height: 45px !important;
  width: 285px !important;
  border-radius: 8px !important;
  background-color: #a0a0a0 !important;
}

.gerty-login-rut{
  height: 3.5%;
  width: 7em;
  margin-top: 4em;
  margin-bottom: 6em;
}

.error-code-login-rut{
  color: red;
  font-family: 'Roboto', sans-serif;
}