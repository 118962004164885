.resume-container {
  background-color: #f4f4f5;
  justify-content: left;
}

.resume-top-grid {
  grid-template-columns: 40% 60%;
  gap: 15px;
  display: flex;
  justify-content: center;
}

.resume-graph {
  display: flex;
  text-align: right;
  vertical-align: middle;
  height: 400px;
  color: white;
  background-color:white;
  padding-bottom: 15px;
}

.resume-graph p {
  height: 33px;
  width: 309px;
  color: #6C787D;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 0;
}

.title-resume {
  height: 41px;
  width: 133px;
  color: #4A4A4A;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 30px;
  display: flex;
  text-align: right;
  vertical-align: middle;
  margin-left: -90px;
  letter-spacing: -0.2px;
  line-height: 41px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.create-verification-resume {
  background-color: #4236ff;
  padding: 25px;
  max-width: 500px;
}

.h2-create-verification-resume {
  color: #FFFFFF;
  font-family: 'Avenir Next', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 33px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 30px;
}
.h2-create-verification-resume-send {
  height: 23px;
  width: 417px;
  color: #FFFFFF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 33px;
  align-items: left;
  padding: 20px;
  text-align: left;
}
.h3-create-verification-resume-send {
  height: 23px;
  width: 417px;
  color: #FFFFFF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  letter-spacing: -0.02px;
  line-height: 33px;
  align-items: left;
  padding: 20px;
  text-align: left;
}
.h4-create-verification-resume-send {
  height: 33px;
  width: 417px;
  color: #FFFFFF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  letter-spacing: -0.02px;
  line-height: 23px;
  align-items: left;
  padding: 25px;
  margin-top: 10px;
  text-align: left;
}

.form-input-section {
  width: 260px;
}

.form-container-resume {
  display: flex;
  flex-wrap: wrap;
}

.form-field-resume {
  width: 100%;
}

.form-action {
  display: flex;
}

.resume-bottom-grid {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 30px;
}

.field-resume {
  margin-top: 10px;
  width: 260px;
  height: 46px;
  align-items: left;
  padding-bottom: 5px;
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  padding: 11px;
  border-radius: 4px;
  outline:none;
}

.field-resume::placeholder {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  padding: 11px;

}

.resume-activity {
  display: flex;
  text-align: right;
  vertical-align: middle;
  background-color: white;
  height: 430px;
}

.resume-activity p {
  height: 33px;
  width: 309px;
  color: #6C787D;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 0;
}

.errors-section {
  color: white;
  padding-bottom: 1px;
  font-size: 10px;
}

.button-section {
  width: 100%;
  align-self: flex-end;
}

.message-section {
  width: 100%;
  align-self: flex-end;

}
.verification-button-resume {
  height: 45px;
  outline:none;
  width: 165px;
  border-radius: 6px;
  background-color: #8E87FF;
  color: #ffffff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-left: 15px;
  align-self: flex-end;
}

@media (max-width: 530px) {
  .form-container-resume {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .verification-button-resume {
    margin-left: 0;
    margin-top: 15px;
  }
}
