 .gerty-blue-navbar{

    margin-bottom: 20.2px;
    height: auto; 
    width: auto; 
    margin-top: 30px;
    margin-left: -30px;
    max-width: 110px; 
    max-height: 120px;
  }

  .gerty-dropdown-navbar{
    max-width: 80px; 
    max-height: 80px;
    margin-right: -35px;

  }
  .container-left-logo{
    float: left;
    display: flex;
    height: 85px;
    background-color: #FFFFFF;
    width: 12%;
  
  }

  
  .container-fluid {
    width: 100%;
    background-color: rgb(65, 32, 32);
}
  
 
  
  .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem; }
  
  .custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem; }
  
  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #007bff;
      background-color: #007bff; }
    .custom-control-input:focus ~ .custom-control-label::before {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #80bdff; }
    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      color: #fff;
      background-color: #b3d7ff;
      border-color: #b3d7ff; }
    .custom-control-input:disabled ~ .custom-control-label {
      color: #6c757d; }
      .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #e9ecef; }
  
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top; }
    .custom-control-label::before {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: #adb5bd solid 1px; }
    .custom-control-label::after {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: no-repeat 50% / 50% 50%; }
  
  td a {
        display:block;
        width:100%;
    }
    
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    .custom-select:focus {
      border-color: #80bdff;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .custom-select:focus::-ms-value {
        color: #495057;
        background-color: #fff; }
    .custom-select[multiple], .custom-select[size]:not([size="1"]) {
      height: auto;
      padding-right: 0.75rem;
      background-image: none; }
    .custom-select:disabled {
      color: #6c757d;
      background-color: #e9ecef; }
    .custom-select::-ms-expand {
      display: none; }
  
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem; }
  
  .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem; }
  
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0; }
  
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0; }
    .custom-file-input:focus ~ .custom-file-label {
      border-color: #80bdff;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:disabled ~ .custom-file-label {
      background-color: #e9ecef; }
    .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: "Browse"; }
    .custom-file-input ~ .custom-file-label[data-browse]::after {
      content: attr(data-browse); }
  

  
  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; 
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
    .nav-link:hover, .nav-link:focus {
      text-decoration: none; }
    .nav-link.disabled {
      color: #6c757d;
      pointer-events: none;
      cursor: default; }
  
  
  .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 70px;
    background-color: black;
}
    .navbar > .container,
    .navbar > .container-fluid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
  
  
  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
  
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
    .navbar-toggler:hover, .navbar-toggler:focus {
      text-decoration: none; }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
      .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .navbar-expand-sm .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-sm .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-sm > .container,
      .navbar-expand-sm > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
      .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
      .navbar-expand-sm .navbar-toggler {
        display: none; } }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
      .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .navbar-expand-md .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-md .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-md > .container,
      .navbar-expand-md > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
      .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
      .navbar-expand-md .navbar-toggler {
        display: none; } }
  

  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      background-color: #FAFAFA; 
      border-bottom: 1px solid rgb(234, 234, 234);
      height: 100px;
    }
      .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
        .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute; }
        .navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      .navbar-expand-lg > .container,
      .navbar-expand-lg > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
      .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
      .navbar-expand-lg .navbar-toggler {
        display: none; } }
  
  
  
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem; }
    .card > hr {
      margin-right: 0;
      margin-left: 0; }
    .card > .list-group:first-child .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .card > .list-group:last-child .list-group-item:last-child {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
  
  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem; }
    
  .rounded-0 {
    border-radius: 0 !important; }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  
  .d-none {
    display: none !important; }
  
  .d-inline {
    display: inline !important; }
  
  .d-inline-block {
    display: inline-block !important; }
  
  .d-block {
    display: block !important; }
  
  .d-table {
    display: table !important; }
  
  .d-table-row {
    display: table-row !important; }
  
  .d-table-cell {
    display: table-cell !important; }
  
  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  
  .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  
  
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important; }
    .d-lg-inline {
      display: inline !important; }
    .d-lg-inline-block {
      display: inline-block !important; }
    .d-lg-block {
      display: block !important; }
    .d-lg-table {
      display: table !important; }
    .d-lg-table-row {
      display: table-row !important; }
    .d-lg-table-cell {
      display: table-cell !important; }
    .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  
  
  .embed-responsive-4by3::before {
    padding-top: 75%; }
  
  .embed-responsive-1by1::before {
    padding-top: 100%; }
  

  
  .float-left {
    float: left !important; }
  
  .float-right {
    float: right !important; }
  
  .float-none {
    float: none !important; }
  
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important; }
    .float-sm-right {
      float: right !important; }
    .float-sm-none {
      float: none !important; } }
  
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important; }
    .float-md-right {
      float: right !important; }
    .float-md-none {
      float: none !important; } }
  
  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important; }
    .float-lg-right {
      float: right !important; }
    .float-lg-none {
      float: none !important; } }
  
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important; }
    .float-xl-right {
      float: right !important; }
    .float-xl-none {
      float: none !important; } }
  

  
  .position-static {
    position: static !important; }
  
  .position-relative {
    position: relative !important; }
  
  .position-absolute {
    position: absolute !important; }
  
  .position-fixed {
    position: fixed !important; }
  
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  

  
  
  .min-vw-100 {
    min-width: 100vw !important; }
  
  .min-vh-100 {
    min-height: 100vh !important; }
  
  .vw-100 {
    width: 100vw !important; }
  
  .vh-100 {
    height: 100vh !important; }
  
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0); }
  
  .m-0 {
    margin: 0 !important; }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important; }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important; }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important; }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important; }
  
  .m-1 {
    margin: 0.25rem !important; }
  
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important; }
  
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important; }
  
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important; }
  
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important; }
  
  .m-2 {
    margin: 0.5rem !important; }
  
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important; }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important; }
  
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important; }
  
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important; }
  
  .m-3 {
    margin: 1rem !important; }
  
  .mt-3,
  .my-3 {
    margin-top: 1rem !important; }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important; }
  
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important; }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important; }
  
  .m-4 {
    margin: 1.5rem !important; }
  
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important; }
  
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important; }
  
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important; }
  
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important; }
  
  .m-5 {
    margin: 3rem !important; }
  
  .mt-5,
  .my-5 {
    margin-top: 3rem !important; }
  
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important; }
  
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important; }
  
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important; }
  
  .p-0 {
    padding: 0 !important; }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important; }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important; }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important; }
  

  
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }
  
  .text-decoration-none {
    text-decoration: none !important; }
  
  .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important; }
  
  .text-reset {
    color: inherit !important; }
  
  .visible {
    visibility: visible !important; }
  
  .invisible {
    visibility: hidden !important; }
  

  
  

  
  button {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    button:hover, button:focus {
      text-decoration: none !important;
      outline: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important; }
  

  
  .ftco-section {
    padding: 7em 0; }
  
  .ftco-no-pt {
    padding-top: 0; }
  
  .ftco-no-pb {
    padding-bottom: 0; }
  
  .heading-section {
    font-size: 28px;
    color: #000; }
    .heading-section small {
      font-size: 18px; }
  
  .img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  
  .navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); }
  
  .navbar-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none !important;
    border: none; }
  
  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0; }
  
  .wrapper {
    width: 100%;
    overflow: hidden; 
  }
  
  #sidebar {
    background-color: #FAFAFA ;
    width: 250px;
    transition: all 0.3s;
    min-width: 250px;
  }

    #sidebar.active {
      min-width: 250px;
      max-width: 250px;
      text-align: center; }
      #sidebar.active ul.components li {
        font-size: 16px; }
        #sidebar.active ul.components li a {
          padding: 10px 0; }
          #sidebar.active ul.components li a span {
            margin-right: 0;
            display: block;
            font-size: 24px; }
      @media (prefers-reduced-motion: reduce) {
        #sidebar .logo {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
    #sidebar ul.components {
      padding: 0;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      @media (prefers-reduced-motion: reduce) {
        #sidebar ul.components {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
    #sidebar ul li {
      font-size: 18px; 
    }
      #sidebar ul li > ul {
        margin-left: 10px; }
        #sidebar ul li > ul li {
          font-size: 14px; }

    #sidebar a{
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        line-height: 14px;
    }

        #sidebar ul li a span {
          margin-right: 15px; }
          @media (max-width: 991.98px) {
            #sidebar ul li a span {
              display: block; } }

      #sidebar ul li.active > a {
        background: transparent;
        color: #fff; }
    @media (max-width: 991.98px) {
      #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -9999px }
        #sidebar.active {
          margin-left: 0 !important; } }
  
  

  
  #content {
    width: 100%;

    padding: 0;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; 
    background-color: #f4f4f5;
}

  







.background-table-verification {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #FAFAFA;
    position:fixed;
    padding:0;
    margin:0;
  
    top:0;
    left:0;
  
    width: 100%;
    height: 100%;
  
  
  }
  

  .container-up-elements{
    display: flex;
    resize: contain;
    background-color: #FFFFFF;
    
  }
  
  .circle {
    height: 31px;
    width: 31px;
    background-color: #D8D8D8;
    border-radius: 100%;
    padding-top: 40px;
    margin-left: 20px;
  }

  .list-unstyled {
    list-style: none;
  }
  .container-down-left-navbar-organization{
    background-color: #FAFAFA;
    border-color: black;
    float: left;
    display: flex;
    width: 200px;
  }
  .container-left-logo{
    float: left;
    display: flex;
    height: 85px;
    background-color: #FFFFFF;
    width: 12%;
    border-width: 50px;
  
  }
  .container-up-navbar-organization{
      background-color: #FFFFFF;
      width: 957px;
      height: 75px;
      width: 75%;
      display: flex;
  }
  .container-right-user-info{
      background-color: #FFFFFF;
      width: 40%;
      height: 85px;
      width: 14%;
      display: flex;
    }
  .container-background-table{
    background-color: #f4f4f5;
    overflow: hidden;
    height: 100%;
  }

 .container-all-table,
 .container-table-info,
 .custom-table {
   height: 100%;
 }
  
  .container-down-elements{
    min-height: 100vh;
    display: flex;
    resize: contain;

  }
 
  .left-target{
    display:flex;
    margin: auto;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .container-all-table{
    border: 0;
  }
  .container-table-info{
    background-color: #f4f4f5;
    height: 100%;
    box-shadow: -10px 10px 20px 0 rgba(30,30,30,0.05);
    overflow-y:auto;
  }

  .container-table-filters{
    background-color: #FFFFFF;
  }
  .a-link-table{
    all:unset;
  }
  .container-up-info-table-header{
    text-align: center;
    font-size: 50px;
    font-family : 'Open Sans', sans-serif;;
    line-height: 41px;
    color: #4A4A4A;
    font-style: bold;
    font-size: 35px;
    display: flex;
    letter-spacing: -0.2px;
    width: 1340px;
    text-align: right;
    vertical-align: middle;

  }
  .container-up-info-table-header h4{
    height: 19px;
    width: 191px;
    color: #9B9B9B;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: right;
    margin-top: 9px;
    margin-left: 250px;
  }
  .container-up-info-table-header h1{
    text-align: center;
    font-size: 50px;
    font-family : 'Open Sans', sans-serif;;
    line-height: 41px;
    color: #4A4A4A;
    font-style: bold;
    font-size: 35px;
    display: flex;
    letter-spacing: -0.2px;
    width: 840px;
    text-align: right;
    vertical-align: middle;
    margin-left: 27px;

  }
  
  .container-up-info-table{
  
    width: 1340px;
    margin-top: 50px;
    display: inline-flex;
    text-align: center;
    margin-bottom: 30px;
  }
  







  .dropdown:checked ~ .section-dropdown{
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  
.sec-center {
  position: relative;
  max-width: 100%;
  text-align: center;
  z-index: 200;
  margin-top: 20px;
  margin-right: 60px;


}
.dark-light:checked + label,
.dark-light:not(:checked) + label{
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 20000;
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  cursor: pointer;
  transition: all 200ms linear;
  box-shadow: 0 0 25px rgba(255,235,167,.45);
}
.dark-light:checked + label{
  transform: rotate(360deg);
}
.dark-light:checked + label:after,
.dark-light:not(:checked) + label:after{
  position: absolute;
  content: '';
  top: 1px;
  left: 1px;
  overflow: hidden;
  z-index: 2;
  display: block;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #102770;
  background-image: url('https://assets.codepen.io/1462889/moon.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms linear;
  opacity: 0;
}
.dark-light:checked + label:after {
  opacity: 1;
}
.dark-light:checked + label:before,
.dark-light:not(:checked) + label:before{
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  background-color: #48dbfb;
  background-image: url('https://assets.codepen.io/1462889/sun.svg');
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms linear;
}
.dark-light:checked + label:before{
  background-color: #000;
}
.light-back{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg');
  background-position: center;
  background-repeat: repeat;
  background-size: 4%;
  height: 100%;
  width: 100%;
  transition: all 200ms linear;
  opacity: 0;
}
.dark-light:checked ~ .light-back{
  opacity: 1;
}
.dropdown:checked + label,
.dropdown:not(:checked) + label{
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 2;
  height: 50px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 220px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  background-color: #FAFAFA;
  cursor: pointer;
  color: #102770;
  box-shadow: 0 12px 35px 0 rgba(255,235,167,.15);
}
.dark-light:checked ~ .sec-center .for-dropdown{
  background-color: #102770;
  box-shadow: 0 12px 35px 0 rgba(16,39,112,.25);
}
.dropdown:checked + label:before,
.dropdown:not(:checked) + label:before{
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: auto;
  pointer-events: none;
}
.dropdown:checked + label:before{
  pointer-events: auto;
}
.dropdown:not(:checked) + label .uil {
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.dropdown:checked + label .uil {
  transform: rotate(180deg);
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.section-dropdown {

  padding: 5px;
  background-color: #FAFAFA;
  top: 70px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  z-index: 2;
  opacity: 0;
  margin-left: -150px;


}

#item-dropdown{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 400px;
  background-color: green;
}

.section-dropdown:before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: '';
  display: block;
  z-index: 1;
}
.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-bottom: 8px solid #111;
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center .section-dropdown:after {
  border-bottom: 8px solid rgb(0, 0, 0);
}
/*
a{
  position: relative;
  color: rgb(0, 0, 0);

  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  color: #4A4A4A;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin: 2px 0;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
    -ms-flex-pack: distribute;

}
*/

.text-a-table{
  position: relative;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  color: #4A4A4A;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin: 2px 0;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
    -ms-flex-pack: distribute;
}
.text-a-table:hover{
  color: #007bff;
}



.text-a-link-table{
  position: relative;
  color: rgb(0, 0, 0);
  font-size: 315px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  color: #4A4A4A;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin: 2px 0;
  text-decoration: none;
  display: inline-flex;

}









a .uil {
  font-size: 22px;
}
.dropdown-sub:checked + label,
.dropdown-sub:not(:checked) + label{
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
    -ms-flex-pack: distribute;
    cursor: pointer;
}
.dropdown-sub:checked + label .uil,
.dropdown-sub:not(:checked) + label .uil{
  font-size: 22px;
}
.dropdown-sub:not(:checked) + label .uil {
  transition: transform 200ms linear;
}
.dropdown-sub:checked + label .uil {
  transform: rotate(135deg);
  transition: transform 200ms linear;
}


.section-dropdown-sub {
  position: relative;
  display: block;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  max-height: 0;
  padding-left: 10px;
  padding-right: 3px;
  overflow: hidden;
  transition: all 200ms linear;
}
.dropdown-sub:checked ~ .section-dropdown-sub{
  pointer-events: auto;
  opacity: 1;
  max-height: 999px;
}
.section-dropdown-sub a {
  font-size: 14px;
}
.section-dropdown-sub a .uil {
  font-size: 20px;
}
.logo {
	position: fixed;
	top: 50px;
	left: 40px;
	display: block;
	z-index: 11000000;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
	transition: all 250ms linear;
}
.logo:hover {
  background-color: transparent;
}
.logo img {
	height: 26px;
	width: auto;
	display: block;
  transition: all 200ms linear;
}
.dark-light:checked ~ .logo img{
  filter: brightness(10%);
}




.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 7px;
    margin-bottom: 4px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }




  .s006 {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #FFFFFF;
    height: 100px;
    align-items: center;
  }
  
  .s006 form legend {
    font-size: 36px;
    color: #fff;
    font-weight: 800;
    text-align: center;
    margin-bottom: 59px;
    display:inline-block
  }
  
 
  
  .s006 form .inner-form .input-field {
    position: relative;
  }

  
  .s006 form .inner-form .input-field input {
    width: 215px;
    height: 40px;
    padding: 5px 5px 5px 35px;
    color: #666;
    line-height: 21px;
    border: 1px solid #CCCCCC;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    border-radius: 3px;
    background-color: #FFFFFF;
  }
  
  .s006 form .inner-form .input-field input.placeholder {
    color: #ccc;
    font-size: 18px;
    display:inline-block;
  }
  
  .s006 form .inner-form .input-field input:-moz-placeholder {
    color: #ccc;
    font-size: 18px;
    display:inline-block;
  }
  
  .s006 form .inner-form .input-field input::-webkit-input-placeholder {
    color: #ccc;
    display:inline-block;
    height: 21px;
    width: 169px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }
  
  .s006 form .inner-form .input-field input:hover, .s006 form .inner-form .input-field input:focus {
    box-shadow: none;
    outline: 0;
  }
  
  .s006 form .inner-form .input-field .btn-search {
    width: 35px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    background: transparent;
    border: 0;
    padding: 0 0 0 5px;
    height: 40px;
  }
  
  .container-select-filters {
    display: flex;
    flex-direction: row;

  }
  .container-select-filters select {
    cursor: pointer;
    height: 40px;
    width: 215px;
    border: 1px solid #CCCCCC;
    display: flex;
    border-radius: 3px;
    text-align: left;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    margin-left: 15px;
  }

  .s006 form .inner-form .input-field .btn-search svg {
    fill: #ccc;
    width: 30px;
    height: 30px;
    transition: all .2s ease-out, color .2s ease-out;
    color: #9B9B9B;
    font-size: 16px;
    letter-spacing: -0.11px;
    line-height: 16px;
  }
  
  .s006 form .inner-form .input-field:hover .btn-search,
  .s006 form .inner-form .input-field:focus .btn-search {
    outline: 0;
    box-shadow: none;
  }
  
  .s006 form .inner-form .input-field:hover .btn-search svg,
  .s006 form .inner-form .input-field:focus .btn-search svg {
    fill: #666;
  }
  
  .s006 form .suggestion-wrap {
    padding: 0 15px;
  }

  .inner-form{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .text-link-table {
    height: 19px;
    width: 180px;
    color: #9B9B9B;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: right;
    margin: auto;
  }







  

  .custom-table {
    min-width: 900px; 
  
  }
    .custom-table thead tr, .custom-table thead th {
      border-top: 2px solid #f4f4f5;
      border-bottom: 2px solid #f4f4f5; 
    }
    .custom-table tbody th, .custom-table tbody td {
      color: #777;
      border-top: 2px solid #f4f4f5;
      border-bottom: 2px solid #f4f4f5;
      height: 21px;
      color: #4A4A4A;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      width: 200px;

    }
    .custom-table thead tr{
      height: 75px;
      overflow: hidden;
      vertical-align: middle;

    }
    .custom-table thead th{
      height: 75px;
      overflow: hidden;
      vertical-align: middle;
      width: 400px;
  


    }
    .custom-table tbody td{
      height: 75px;
      overflow: hidden;
      vertical-align: middle;
   


    }
    .custom-table tbody th{
      height: 75px;
      overflow: hidden;
      vertical-align: middle;
      align-items: center;
      width: 200px;
    }

    .custom-table tbody tr{
      height: 75px;
      overflow: hidden;
      vertical-align: middle;
      width: px;

    }
  
  /* Custom Checkbox */
  .control {
    display: block;
    text-align: center;
    position: relative;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 18px; }

  .control.no-margin {
    margin-bottom: 0; }
  
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0; 
    text-align: center;}
  
  .control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 2px solid #ccc;
    text-align: center;
    margin-left: 40px;
    background: transparent; }

  .control__indicator.no-margin {
    margin-left: 0px; }
  
  .control--radio .control__indicator {
    border-radius: 50%; }
  
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    border: 2px solid #007bff; }
  
  .control input:checked ~ .control__indicator {
    border: 2px solid #007bff;
    background: #007bff; }
  
  .control input:disabled ~ .control__indicator {
    background: #007bff;
    opacity: 0.6;
    pointer-events: none;
    border: 2px solid #ccc; }
  
  .control__indicator:after {
    font-family: 'icomoon';
    content: '\e5ca';
    position: absolute;
    display: none; }
  
  .control input:checked ~ .control__indicator:after {
    display: block;
    color: #007bff; }
  
  .control--checkbox .control__indicator:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -52%);
    -ms-transform: translate(-50%, -52%);
    transform: translate(-50%, -52%); }
  
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b; }
  
  .control--checkbox input:disabled:checked ~ .control__indicator {
    background-color: #007bff;
    opacity: .2;
    border: 2px solid #007bff; }

  .control__indicator-text {
    padding-left: 30px;
    text-align: justify; }

/* Solid border */
hr {
  border-top: 2px solid #f4f4f5;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
  
.container-user-organization-name{
  display: inline-block;
  margin-left: 50px;
  text-align: left;
  margin-right: 200px;
  text-align: left;background-color: #FAFAFA;
}
.label-name{
  height: 21px;
  width: 277px;
  color: #000000;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  margin-left: -40px;
  font-style: bold;
  text-align: left;
}
.label-correo{
  height: 21px;
  width: 77px;
  color: #4A4A4A;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  margin-left: -40px;
  text-align: left;

}
#dropdown{
  background-color: green;
}

.meter {
  box-sizing: content-box;
  height: 10px; /* Can be anything */
  position: relative;
  background: #d8d8dd;
  width: 29px;
  padding: 0px;
  margin-top: 25px;
  margin-right: 2.5px;
}
.meter > span {
  display: block;
  height: 10px;
  width: 29px;


  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
.meter > span:after,


.animate > span:after {
  display: none;
}



.orange > span {
  background-image: linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-image: linear-gradient(#f0a3a3, #f42323);
}

* {
  box-sizing: border-box;
}
.container-status-bar{
  display: flex;
  width: 300px;
  height: 100%;

}

.helper-text{
  margin-left: 950px;
}

.text-estatus-table{
  margin-left: -50px;
}

.text-rut-table{
  height: 21px;
  width: 97px;
  color: #9B9B9B;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}

.loading-row div {
  display: flex;
  justify-content: center;
  padding: 15px;
}