.container-slider {
  width: 500px;
  height: 375px;
  margin: 23px 5px 0;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
}

.image-text {
  position: relative;
  margin-top: 61%;
  color: black;
  font-size: small;
}

@media screen and (max-width: 700px) {
  .container-slider {
    margin: 10px 0 0;
    max-width: 100%;
    width: 360px;
    height: 270px;
    position: relative;
    display: flex;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
  }
  .slide {
    width: 100%;
    height: 90%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 90%;
  }
  /* .image-text {
    position: relative;
    margin-top: 40vh;
    margin-left: 44%;
    color: black;
    font-size: small;
  } */
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
  z-index: 9;
}
.prev {
  top: 50%;
  left: 10px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 10px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background: gray;
  border: 1px solid #ffffff;
}
.dot.active {
  background: #4236ff;
}
