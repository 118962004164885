.display-image__image-container {
  width: 160px;
  margin: 12.5px;
}

.display-image__image-container,
.display-image__carnet-container {
  position: relative;
  cursor: pointer;
}

.display-image__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.display-image__search-icon-container {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: .5s ease all;
}

.display-image__search-icon {
  font-size: 35px;
  color: #4236ff;
}

.display-image__carnet-container:hover .display-image__search-icon-container,
.display-image__image-container:hover .display-image__search-icon-container {
  display: flex;
  background-color: #4236ff47;
}

.display-image__carnet-container {
  width: 214px;
  height: 180px;
  margin: 18px;
}

.display-image__carnet {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.display-image__title {
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  color: black
}

.display-image__subtitle {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  color: #4A4A4A;
}

@media only screen and (min-width: 768px) {
  /* Laptops / Desktops */

}