.home-container {
  display: flex;
  background-color: #4236FF;
  overflow: hidden;
  height: 100%;
}

.home-container::after {
  content: "";
  height: 1023px;
  width: 890px;
  transform: rotate(100deg);
  background: linear-gradient(314.36deg, rgba(27,22,94,0) 0%, #1B165E 100%, #A6C4FF 100%);
  border-radius: 100%;
  position: absolute;
  top: 90px;
  left: 0;
}

.home-container .logo {
  position: relative;
  top: inherit;
  left: inherit;
}

.home-container.status-page .container {
  justify-content: space-evenly;
}

.home-container.status-page .backend-status-icon {
  font-size: 150px;
}

.home-container.status-page .backend-status-icon.backend-status-icon__ok {
  color: #008000;
}

.home-container.status-page .backend-status-icon.backend-status-icon__not-ok {
  color: #ff0018;
}

.home-container .container {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.home-container .title {
  height: 88px;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 44px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans', sans-serif;
}

.home-container .message {
  background: transparent;
  color: #ffffff;
}

.home-container img {
  max-width: 100%;
}

.home-container .logo {
  width: 100px;
}

.home-container .img-gerty {
  width: 200px;
}