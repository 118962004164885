.locations-container {
  background: #ffffff;
  width: 100%;
  height: 100%;
  font-family: 'Avenir Next', sans-serif;
  color: #4A4A4A;
  display: flex;
}

.locations-container .locations-body-section__empty_locations {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
}

.locations-container .locations-body-section__active_location {
  flex-grow: 1;
}

.locations-container .location-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
}

.locations-container .location-body-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
}

.locations-container .location-container .location-title-section {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  width: 100%;
  display: flex;
  align-items: center;
}

.locations-container .location-container .location-title-section .location-title-section__name {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  margin-right: 15px;
}

.locations-container .location-container .location-title-section .location-title-section__edit_icon {
  height: 15px;
  width: 15px;
  color: #B3B3B3;
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 15px;
  cursor: pointer;
}

.locations-container .location-container .location-title-section .location-title-section__edit_form,
.locations-container .location-container .location-title-section .location-title-section__edit_form .location-title-section__edit_form_body {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.locations-container .location-container .location-title-section .location-title-section__edit_form.input-has-error {
  flex-direction: column;
  align-items: flex-start;
}

.locations-container .location-container .location-title-section .location-title-section__edit_form.input-has-error .location-title-section__edit_input {
  border-color: #df4759;
}

.locations-container .location-container .location-title-section .location-title-section__error {
  color: #df4759;
  font-size: 12px;
}

.locations-container .location-container .location-title-section .location-title-section__edit_input,
.locations-container .location-container .location-title-section .location-title-section__cancel_btn,
.locations-container .location-container .location-title-section .location-title-section__confirm_btn {
  font-size: 16px;
  border-radius: 6px;
}

.locations-container .location-container .location-title-section .location-title-section__cancel_btn,
.locations-container .location-container .location-title-section .location-title-section__confirm_btn {
  height: 40px;
  width: 100px;
  cursor: pointer;
  transition: .5s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locations-container .location-container .location-title-section .location-title-section__confirm_btn {
  margin-right: 5px;
}

.locations-container .location-container .location-title-section .location-title-section__btns_container {
  margin-left: 5px;
  display: flex;
}

.locations-container .location-container .location-title-section .location-title-section__cancel_btn:hover,
.locations-container .location-container .location-title-section .location-title-section__confirm_btn:hover {
  opacity: .8;
}

.locations-container .location-container .location-title-section .location-title-section__edit_input {
  width: 200px;
  height: 40px;
  padding: 5px;
  border: 1px solid #DDDDDD;
}

.locations-container .location-container .location-title-section .location-title-section__edit_input:focus {
  border: 1px solid #797979;
  outline: none;
}

.locations-container .location-container .location-title-section .location-title-section__confirm_btn {
  background-color: #4236FF;
  color: #FFFFFF;
  border: 1px solid #4236FF;
}

.locations-container .location-container .location-title-section .location-title-section__cancel_btn {
  background-color: #DDDDDD;
  border: 1px solid #DDDDDD;
}

.locations-container .location-container .location-qr-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 62px;
  width: 100%;
}

.locations-container .location-container .location-qr-section .location-qr-section__title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.locations-container .location-container .location-qr-section .location-qr-section__message {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 10px;
}

.locations-container .location-container .location-qr-section .location-qr-section__container {
  box-sizing: border-box;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 29px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locations-container .location-container .location-qr-section .location-qr-section__download_qr {
  border: 1px solid #4236FF;
  border-radius: 6px;
  background-color: #FFFFFF;
  color: #4236FF;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 13px 0;
  width: 250px;
  margin-top: 25px;
  cursor: pointer;
  transition: .5s ease all;
}

.locations-container .location-container .location-qr-section .location-qr-section__download_qr:hover {
  background-color: #4236FF;
  color: #FFFFFF;
}

.locations-container .location-container .location-footer-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  color: #6C787D;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
}

.locations-container .location-container .location-footer-section .location-footer__deactivate_location {
  text-decoration: underline;
  cursor: pointer;
}

.locations-container .location-container .location-footer-section .location-footer__deactivate_location:hover {
  color: #4F5557FF;
}

.locations-container .locations-body-section__locations_container {
  border-right: 1px solid #E2E1E0;
  overflow: auto;
}

.locations-container .locations-body-section__locations_container .tab-content {
  height: calc(100% - 68px);
  overflow: auto;
}

.locations-container .locations-body-section__locations_container .locations-body-section__filter .nav-item {
  list-style: none;
}

.locations-container .locations-body-section__locations_container .locations-body-section__filter .nav-item .nav-link {
  padding: 26px 80px;
  color: #2B3034;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #E9EAEA;
  border-radius: 0;
  opacity: 0.4;
}

.locations-container .locations-body-section__locations_container .locations-body-section__filter .nav-item .nav-link.disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.locations-container .locations-body-section__locations_container .locations-body-section__filter .nav-item .nav-link.active {
  border-bottom: 2px solid #4236FF;
  opacity: 1;
}

.locations-container .locations-body-section__locations_container .locations-body-section__filter .nav-item .nav-link:hover:not(.disabled) {
  background-color: #E2E1E0;
  opacity: 1;
}

.locations-container .locations-body-section__locations_container .locations_container__location {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid #E9EAEA;
  cursor: pointer;
}

.locations-container .locations-body-section__locations_container .locations_container__location:hover {
  background-color: #E2E1E0;
}

.locations-container .locations-body-section__locations_container .locations_container__location.locations_container__location_active {
  background-color: #E2E1E0;
}

.locations-container .locations-body-section__locations_container .locations_container__location .locations_container__location_name {
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.locations-container .locations-body-section__locations_container .locations_container__location .locations_container__location_created_at {
  color: #6C787D;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
}

.locations-container .locations-body-section__locations_container .locations_container__location.locations_container__location_disabled .locations_container__location_name,
.locations-container .locations-body-section__locations_container .locations_container__location.locations_container__location_disabled .locations_container__location_created_at {
  color: #9DA5AD;
}

.locations-title-section__download_btn {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 13px 0;
  width: 200px;
  border-radius: 6px;
  background-color: #4236FF;
  cursor: pointer;
  border: 1px solid #4236FF;
  transition: .5s ease all;
}

.locations-title-section__download_btn:hover {
  color: #4236FF;
  background-color: transparent;
}

@media (max-width: 991.98px) {
  .locations-container .locations-body-section__locations_container .locations-body-section__filter .nav-item {
    width: 100%;
  }
  .locations-container .locations-body-section__locations_container .locations-body-section__filter .nav-item .nav-link {
    padding: 20px 0;
    font-size: 14px;
    max-width: 100%;
    width: 100%;
  }
  .locations-title-section__download_btn {
    width: 180px;
    padding: 10px 0;
  }
  .locations-container .location-container {
    padding: 30px 15px;
  }
  .locations-container .location-container .location-footer-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .locations-container .location-container .location-qr-section .location-qr-section__message {
    text-align: left;
  }
  .locations-container .location-container .location-qr-section {
    margin-top: 30px;
  }
  .locations-container .location-container .location-qr-section .location-qr-section__container {
    padding: 10px;
  }
  .locations-container .location-container .location-qr-section .location-qr-section__download_qr,
  .locations-container .location-container .location-qr-section .location-qr-section__container,
  .locations-container .location-container .location-qr-section .location-qr-section__container canvas {
    max-width: 100%;
  }
  .modal-card, .modal-content {
    margin: 0;
  }
  .locations-container .location-container .location-title-section .location-title-section__edit_form {
    flex-direction: column;
    align-items: flex-start;
  }
  .locations-container .location-container .location-title-section .location-title-section__btns_container {
    margin-left: 0;
    margin-top: 5px;
  }
  .locations-container .location-container .location-title-section .location-title-section__edit_input {
    width: 205px;
  }
}