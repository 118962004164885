.background-capture {
  background-color: white;
  position: relative;
  min-height: 100%;
  width: 100%;
}

.background-capture .capture {
  width: 100%;
}

#button-2-captured {
  padding-bottom: 2.4%;
}
/* .camera-capture {
  background-color: white;
  width: 600px;
  height: 550px;
  border-radius: 50px;
  padding: 2%;
} */

/* .border-container {
  border-radius: 10px black;
} */

/* .box-felipe {
  background-image: url(https://placeimg.com/640/480/nature);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  width: 600px;
  height: 400px;

  overflow: hidden;
  display: inline-block;
}

.box-felipe::before {
  content: "";
  display: block;


  width: 50%;
  height: 0;
  padding-bottom: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  border: solid 400px rgba(31, 44, 122, 0.5);
  border-radius: 50%;
  mask-image: radial-gradient(
    circle farthest-side at center,
    transparent 49%,
    white 50%
  );
}

.container-felipe {
  width: 1300px;
  margin: 30px auto;
}

  .prueba2 {
  width: 500px;
  height: 500px;
  background: url(http://lorempixel.com/200/200/abstract/6);
  mask-image: radial-gradient(
    circle farthest-side at center,
    transparent 49%,
    white 50%
  );
}
*/
.marco-carnet {
  margin-top: 22%;
  width: 480px;
  height: 300px;
  border-radius: 5%;
  border: 10px solid rgba(68, 64, 245, 0.9);
  z-index: 3;
}

.filtro-circular {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  border: solid 20% rgba(86, 85, 85, 0.7);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  mask-image: radial-gradient(
    circle farthest-side at center,
    transparent 49%,
    white 50%
  );
}

.filtro-circular1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  border: solid 400px rgba(86, 85, 85, 0.7);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  mask-image: radial-gradient(
    circle farthest-side at center,
    transparent 40%,
    white 10%
  );
}

.filtro-cuadrado1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  border: solid 40px rgba(86, 85, 85, 0.7);
  border-radius: 5%;
  transform: translate(-50%, -50%);
  /* mask-image: linear-gradient(to top, transparent 10%, #fff, top 100% 20%); */
  /* position: absolute;
  top: 100%;
  left: 50%;
  width: 600px;
  transform: translate(-50%, -50%);
  border: solid 70px rgba(86, 85, 85, 1);
  z-index: 5; */
}

.borde-nuevo {
  /* Position */
  position: relative;
  width: 500px;
  height: 375px;
  
  /* Box settings */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.img-template-horizontal {
  align-self: center;
  z-index: 2;
  position: absolute;
  height: 90%;
  margin-top: 5%;
  margin-bottom: 5%;

}

.img-template-vertical {
  align-self: center;
  z-index: 2;
  position: absolute;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.borde {
  overflow: hidden;
  display: block;
  position: absolute;
  /* left: 31.5%; */
  /* width: 550px; */
  /* height: 560px; */
  border: 20px solid rgba(86, 85, 85, 0.7);

  /* margin-left: 30%; */
  /* height: 91%; */
  /* border: 10% solid red; */
  border-radius: 5%;
  z-index: 2;
}

.borde1 {
  overflow: hidden;
  display: inline-block;
  position: absolute;
  /* left: 31.5%; */
  /* width: 550px; */
  /* height: 350px; */
  /* border: 20px solid white; */
  border-radius: 0%;
  z-index: 2;
}

.borde-carnet {
  overflow: hidden;
  display: inline-block;
  position: absolute;
  /* width: 560px;
  height: 565px; */
  /* left: 31.5%;
  width: 550px;
  height: 560px;
  border: 20px solid white; */
  margin-top: -0.5em;
  margin-left: -0.5em;
  border-radius: 1.7em;
  border: 1.4em solid white;
  z-index: 3;
}

.borde-carnet1 {
  overflow: hidden;
  display: inline-block;
  position: absolute;
  /* font-size: 8px;
  width: 18em;
  height: 15em;
  border-radius: 3em;
  box-sizing: border-box;
  border: 2em solid rgba(86, 85, 85, 0.7);
  border-right-width: 2em;
  border-left-width: 2em;
  z-index: 3; */
  border-radius: 30px;
  box-shadow: inset 0 0 0 20px rgba(86, 85, 85, 0.9);
}

.rectangle0 {
  position: absolute;
  margin-top: 2%;
  /* margin-left: 32%; */
  /* width: 700px; */
  width: 100%;
  height: 5%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* margin-left: 22%; */
  background: red;
  z-index: 2;
}

.rectangle1 {
  position: absolute;
  width: 10%;
  height: 80vmin;
  margin-left: 20vmin;
  background: red;
  z-index: 2;
}
.rectangle2 {
  position: absolute;
  width: 150px;
  height: 560px;
  margin-left: 70%;
  background: white;
  z-index: 2;
}

.rectangle3 {
  position: absolute;
  width: 700px;
  height: 70px;
  background: rgba(86, 85, 85, 1);
  z-index: 2;
}

.rectangle4 {
  position: absolute;
  width: 150px;
  height: 560px;
  margin-left: 69%;
  background: white;
  z-index: 2;
}

.img-face-circle {
  position: absolute;
  top: 315px;
  margin-top: 0;
  left: 50%;
  z-index: 2;
  border: solid 210px white;
  border-radius: 0;
  transform: translate(-50%, -50%);
  mask-image: radial-gradient(
    circle farthest-side at center,
    transparent 9em,
    white 20%
  );
}

@media (max-width: 639px){
  .img-face-circle {
    top: 0;
    margin-top: 17em;
    border: solid 430px white;
    border-radius: 50%;
    mask-image: radial-gradient(
      circle farthest-side at center,
      transparent 9.5em,
      white 20%
    );
  }
}

.img-carnet-rectangle {
  overflow: hidden;
  display: inline-block;
  position: absolute;
  left: 32%;
  top: 2%;
  width: 530px;
  height: 400px;
  border: 10px solid white;
  border-radius: 5%;
  z-index: 2;
}

.button-capture {
  height: 4.39%;
  width: 20em;
  background-color: #4236ff;
  border-radius: 8px;
  margin-top: 2em;
  margin-bottom: 8%;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
  z-index: 3;
  overflow: hidden;
  color: white;
  /* padding-top: 1em; */
}

.button-capture:hover {
  color: white;
}

.button-contine-capture {
  height: 4.39%;
  width: 20rem;
  background-color: #4236ff;
  border-radius: 8px;
  margin-top: 1em;
  margin-bottom: 8%;
  color: white !important;
  font-family: 'Nunito Sans', sans-serif;
  z-index: 3;
  padding-top: 0.8em;
}
.button-contine-capture:hover {
  color: white;
}

.button-again-capture {
  height: 4%;
  width: 20rem;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 1em;
  color: #4236ff;
  border: 3px solid #4236ff;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  z-index: 3;
}

/* .icon_button {
  margin-right: 10%;
  font-family: "Nunito Sans";
} */

.text_in_button {
  position: relative;
  left: 20%;
  font-family: "Nunito Sans";;
}

#circulo {
  position: absolute;
  margin-top: 20%;
  margin-left: 50%;
  width: 150px;
  height: 150px;
  background: #8ac007;
  border-radius: 1000px;
  color: transparent;
  z-index: 2;
}

@media (max-device-width: 500px) {
  .filtro-circular1 {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    border: solid 400px rgba(86, 85, 85, 0.7);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    mask-image: radial-gradient(
      circle farthest-side at center,
      transparent 40%,
      white 10%
    );
  }
}

.title-capture {
  padding: 1%;
  z-index: 3;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  align-self: center;
  flex: auto;
  margin-bottom: 0;
}

.subtitle-capture {
  align-self: center;
  z-index: 3;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 0;
}

.cancel-button {
  display: block;
  color: #4236ff;
  text-align: left;
  padding-bottom: 1em;
  margin-left: 23em;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.camera-web {
  align-self: center;
}

.full-container-of-cam1 {
  padding-top: 2.22%;
  display: flex;
  flex-direction: column;
}

.full-container-of-cam2 {
  padding-top: 2.22%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.full-container-of-cam3 {
  padding-top: 2.22%;
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 50%;
}

@media (max-device-width: 500px) {

  .full-container-of-cam1 {
    padding-top: 2.22%;
    display: flex;
    flex-direction: column;
  }
  .full-container-of-cam2 {
    padding-top: 2.22%;
    display: flex;
    align-self: center;
    flex-direction: column;
  }

  .full-container-of-cam3 {
    padding-top: 2.22%;
    display: flex;
    align-self: center;
    flex-direction: column;
  }
  .cancel-button {
    /* display: block; */
    flex: auto;
    width: min-content;
    padding-right: 0em;
    margin-left: 1em;
    color: #4236ff;
    /* text-align: left;
    width: 7.02%;
    padding-bottom: 1em;
    color: #4a4a4a;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px; */
  }

  .title-capture {
    font-size: 27px;
    padding: 1%;
    color: black;
    z-index: 3;
    height: 8.37%;
    width: 75.73%;
    color: #333333;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.17px;
    line-height: 34px;
    align-self: center;
    margin-top: 1.6em;
    margin-bottom: 1em;
  }

  .subtitle-capture {
    align-self: center;
    color: black;
    z-index: 3;
    height: 5.67%;
    width: 71.2%;
    color: #333333;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
  }
}

.captured {
  margin-top: 5%;
  padding-top: 2.22%;
  width: 90%;
}

.captured.captured__carnet {
  margin-top: 15px;
  padding-top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.captured.captured__carnet .slider-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.captured.captured__carnet .slider-container .container-slider {
  margin: 0;
}

#take-picture-face {
  padding-bottom: 2%;
}

#button-picture-face {
  margin-bottom: 0%;
  padding-top: 1em;
}

#button-picture-face:hover {
  color: white;
}

video {
  height: 33.87%;
}

.icon-smiling {
  position: absolute;
  margin-top: 25em;
  margin-left: 8em;
  z-index: 2;
}

/* gallery for cartnet Photos */

.list {
  display: flex;
  overflow-x: scroll;

  /* prevent accidentally going back or triggering other swipe events */
  overscroll-behavior: contain;

  /* hide scroll bar */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* momentum scroll on iOS */
  -webkit-overflow-scrolling: touch;

  /* disable vertical scroll for touch */
  touch-action: pan-x;

  /* scroll snapping */
  scroll-snap-type: x mandatory;
}

/* hide scroll bar */
.list::-webkit-scrollbar {
  display: none;
}

.item {
  height: 224px;
  width: 100%;
  flex-shrink: 0;

  /* scroll snapping */
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

/* 
  The styling below is only for the decoration of this demo, 
  it has no impact on scrolling or scroll behavior
*/
.indicatorsList,
.list {
  list-style: none;
  padding: 0;
}

.list {
  border: 5px solid #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 1);
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.4);
}

.item:nth-child(4n + 1) {
  background-color: #facd3d;
}
.item:nth-child(4n + 2) {
  background-color: #5626c4;
}
.item:nth-child(4n + 3) {
  background-color: #e60576;
}
.item:nth-child(4n + 4) {
  background-color: #2cccc3;
}

.indicatorsList {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 1);
}

.indicator:not(:last-child) {
  margin-right: 16px;
}

.indicator.active {
  background-color: #e60576;
}

.viewport {
  max-width: 420px;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

/* html,
body {
  height: 100%;
}

body {
  font-family: "Fredoka One", serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #181818;
} 
*/
.panic {
  column-count: 2;
  column-gap: 0;
}

@media screen and (max-width: 700px) {
  .panic {
    column-count: 1;
  }
}
