.dashboard-alert__popup {
  background-color: #FFFFFF;
  box-shadow: -10px 10px 20px 0 rgba(30,30,30,0.05);
  color: #4A4A4A;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 16px;
}
.swal2-title.dashboard-alert__title {
  height: 83px;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  border-bottom: 1px solid #E2E1E0;
  padding: 30px;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
}
.swal2-close.dashboard-alert__closeButton {
  color: #4A4A4A;
  padding: 41.5px 35px;
  position: absolute;
  font-size: 26px;
  font-family: 'Avenir Next', sans-serif;
}
.swal2-close.dashboard-alert__closeButton:hover {
  opacity: .7;
  color: #4A4A4A;
}
.swal2-html-container.dashboard-alert__container {
  line-height: 21px;
  color: #4A4A4A;
  letter-spacing: 0;
  font-size: 14px;
  text-align: left;
  padding: 30px;
  margin: 0;
  font-family: 'Avenir Next', sans-serif;
}
.swal2-styled.swal2-confirm.dashboard-alert__confirmButton {
  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  border-radius: 6px;
  width: 205px;
  font-size: 14px;
  line-height: 16px;
  padding: 14px;
  margin: 0 15px;
  font-family: 'Avenir Next', sans-serif;
  background-color: #4236FF;
}
.swal2-styled.swal2-cancel.dashboard-alert__cancelButton {
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  width: 205px;
  color: #4A4A4A;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 16px;
  padding: 14px;
  margin: 0 15px;
  font-family: 'Avenir Next', sans-serif;
}
.dashboard-alert__loader {

}
