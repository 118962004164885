.display-attempt-images {
  padding: 30px;
}
.display-attempt-images__face {
  display: flex;
  flex-wrap: wrap;
  margin: -12.5px;
  padding-bottom: 25px;
}

.display-attempt-images__emotion {
  display: flex;
  flex-wrap: wrap;
  margin: -12.5px;
  padding-bottom: 25px;
}

.display-attempt-images__document {
  display: flex;
  flex-wrap: wrap;
  margin: -18px;
  padding-bottom: 25px;
}

.display-attempt-images__title {
  color: #4B535A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 33px;
  text-align: left;
}

.display-attempt-images__subtitle {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  color: #4A4A4A;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
}

.display-attempt-images__info {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  color: blue;
}

.display-attempt-images__info-carnet {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-family: 'Avenir Next', sans-serif;
  color: blue;
  margin: 18px;
}