.main-div {
  flex: content;
  background-color: #FFFFFF;
  height: 100%;
  position: fixed; /*TODO porque fixed? necesito initial para tener scroll*/
  width: 100%;
}

.app-version {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #ffffff;
  font-size: 10px;
  opacity: .5;
  font-weight: 800;
}

@media (min-width: 992px) {
  .main-div {
    min-height: 100vh;
    position: initial;
  }
  .app-version {
    color: #000000;
  }
}

.main-wrapper {
  flex: content;
}

body {
  min-height: 100vh;
  margin:0;
  padding:0;
}
html {
  margin:0;
  padding:0;
}

h1, p {
  margin:0 0 1em;
} 