.admin-layout {
  display: flex;
  background: #F4F4F5;
  flex-direction: column;
  height: 100vh;
  font-family: "Avenir Next", sans-serif;
}

.admin-layout .admin-layout__body {
  display: flex;
  height: calc(100% - 70px);
}

.admin-layout #sidebar {
  background-color: #FFFFFF;
  border: none;
  border-right: 1px solid #E2E1E0;
  width: 250px;
  position: fixed;
  height: 100%;
}

.admin-layout #sidebar .admin-layout__sidebar-link {
  padding: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #2B3034;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-decoration: none;
}

.admin-layout #sidebar .admin-layout__sidebar-link.active {
  font-weight: 800;
}

.admin-layout #sidebar .admin-layout__sidebar-link:hover {
  background-color: #f4f4f5;
}

.admin-layout #sidebar .admin-layout__sidebar-link .circle {
  margin-right: 15px;
  margin-left: 0;
  padding-top: 31px;
}

.admin-layout .admin-layout__top_bar {
  background-color: #FFFFFF;
  border: none;
  border-bottom: 1px solid #E2E1E0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  height: 70px;
}

.admin-layout .admin-layout__top_bar .gerty-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-layout .admin-layout__top_bar .gerty-logo .gerty-blue-navbar {
  margin-bottom: 0;
  margin-top: 0;
}

.admin-layout .admin-layout__top_bar .navbar-nav {
  height: 100%;
}

.admin-layout .admin-layout__top_bar .gerty-dropdown-navbar {
  margin-right: 0;
}

.modal-open {
  padding-right: 0 !important;
}

.admin-layout .admin-layout__top_bar .container-user-organization-name {
  margin-left: 0;
  margin-right: 0;
  background-color: #FFFFFF;
  max-width: calc(100% - 70px - 55px);
}

.admin-layout .admin-layout__top_bar .container-user-organization-name .label-name,
.admin-layout .admin-layout__top_bar .container-user-organization-name .label-correo {
  margin-left: 0;
  width: auto;
  margin-bottom: 0;
  color: #4A4A4A;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.admin-layout .admin-layout__top_bar .container-user-organization-name .label-name {
  font-weight: 800;
  height: auto;
  max-height: 35px;
}

.admin-layout .admin-layout__top_bar .container-user-organization-name .label-correo {
  font-weight: 100;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization {
  margin-top: 0;
  margin-right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 283px;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .dropdown-arrow {
  position: absolute;
  right: 37px;
}

.admin-layout .admin-layout__top_bar .navbar-collapse {
  justify-content: flex-end;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .text-a-table {
  margin: 0;
  padding: 0;
  width: 70px;
  height: 70px;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown {
  top: 75px;
  margin-left: 0;
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 5px;
  width: calc(100% - 30px);
  padding: 0;
  border: 2px solid #E9EAEA;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown a {
  padding: 15px;
  border-radius: 0;
  color: #4A4A4A;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  width: 100%;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown a:hover {
  color: #4A4A4A;
  background-color: #E9EAEA;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown a:not(:last-child) {
  border-bottom: 1px solid #E9EAEA;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown a:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown a:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown:after {
  content: none;
}

.admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown.section-dropdown-desapear {
  left: -999999px;
}

.admin-layout .dropdown:checked + label, .dropdown:not(:checked) + label {
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: flex-start;
}

.admin-layout .admin-layout__top_bar .gerty-logo {
  width: 250px;
}

.admin-layout .admin-layout__content {
  transition: all 0.3s;
  background-color: #f4f4f5;
  flex-grow: 1;
  padding: 55px 50px 55px 300px;
  width: 100%;
  overflow: auto;
}

.admin-layout .admin-layout__content.admin-layout__content_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-verifications {
  height: calc(100% - 100px);
  overflow: auto;
}

.admin-layout .admin-layout__content .admin-layout__content-content {
  height: calc(100% - 64px);
  overflow: hidden;
  min-height: 450px;
}

.admin-layout .admin-layout__content .admin-layout__content-title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-layout .admin-layout__content .admin-layout__content-title-container .admin-layout__content-title-title {
  color: #4A4A4A;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 41px;
}

.admin-layout .admin-layout__content .admin-layout__content-title-container .admin-layout__content-title-help a {
  color: #9B9B9B;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.admin-layout .admin-layout__content .admin-layout__content-title-container .admin-layout__content-title-help a:hover {
  opacity: .7;
}

.admin-layout .admin-layout__top_bar .gerty-dropdown-navbar {
  width: 70px;
  height: 70px;
}

@media (max-width: 991.98px) {
  .admin-layout .admin-layout__content {
    padding: 15px;
  }
  .admin-layout .admin-layout__content .admin-layout__content-content {
    height: calc(100% - 61px);
  }
  .admin-layout .admin-layout__top_bar .gerty-logo {
    width: 110px;
  }
  .admin-layout .admin-layout__content .admin-layout__content-title-container .admin-layout__content-title-title {
    font-size: 20px;
  }
  .admin-layout .admin-layout__top_bar {
    padding: 0 15px;
  }
  .admin-layout .admin-layout__top_bar .gerty-logo .gerty-blue-navbar {
    margin-left: 0;
  }
  .admin-layout .dropdown:checked + label, .dropdown:not(:checked) + label {
    justify-content: flex-end;
    margin-right: 35px;
  }
  .admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .dropdown-arrow {
    right: -30px;
  }
  .admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization .section-dropdown {
    left: 30px;
  }
  .admin-layout .admin-layout__top_bar .navbar-nav #dropdown-organization {
    width: auto;
  }
}