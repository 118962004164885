.container-validation {
  background-color: white;
  /* align-content: center; */
  justify-items: center;
  display: block;
  flex: content;
  height: 100%;
}

.overflow-y {
  height: 100vh;
  overflow-y: auto;
}

.container-validation__manual-validation {
  padding-top: 3rem;
}

.container-validation__manual-validation .titulo-register {
  width: auto;
}

.icon-error {
  height: 50px;
  width: 50px;
  margin-top: 4em;
  margin-bottom: 2em;
  align-content: center;
  margin-left: 0em;
  /* margin-left: 48.26%;
  margin-right: 48.26%; */
}

.icon-loading {
  /* animation: fa-spin 0.5s linear infinite; */
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 2rem;
  margin-top: 15%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-success-account-ready {
  height: 50px;
  width: 50px;
  margin-top: 7em;
  margin-bottom: 2.34%;
  align-content: center;
  margin-left: 0em;
}

.icon-success {
  height: 50px;
  width: 50px;
  margin-top: 5%;
  margin-bottom: 2%;
  align-content: center;
  margin-left: 0em;
}
.icon-success-verificacion-pendiente {
  height: 50px;
  width: 50px;
  margin-top: 10%;
  margin-bottom: 2em;
  align-content: center;
  margin-left: 0em;
}

.container-validation #circle-empty {
  background: lightblue;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: transparent;
  margin-top: 217px;
  margin-bottom: 2.34%;
}

.ticket-container {
  box-sizing: border-box;
  height: 22px;
  width: 19.89px;
  border: 3px solid #ffffff;
  transform: scaleX(-1) rotate(-90deg);
}

.text-validation {
  height: 34px;
  width: 174px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-left: 43.96%;
  margin-right: 43.96%;
  margin-bottom: 41px;
}

.text-complete-scan {
  height: 34px;
  width: 211px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-left: 42.67%;
  margin-right: 42.67%;
  margin-bottom: 41px;
}

.text-complete-verification {
  height: 34px;
  /* width: 247px; */
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  /* margin-left: 17.07%;
  margin-right: 17.07%; */
  margin-bottom: 1em;
}

.text-failed-verification {
  height: 34px;
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  /* margin-left: 42.74%;
  margin-right: 42.67%; */
  margin-bottom: 1em;
}

.text-verifying {
  height: 34px;
  width: 131px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 2.34%;
  margin-top: 2.96%;
}

.text-account-ready {
  height: 34px;
  width: 284px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  margin-bottom: 2%;
}

#continue-validation {
  height: 45px;
  width: 285px;
  background-color: #4236ff;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 613px;
}

#try-again-validation {
  height: 45px;
  width: 285px;
  background-color: #e46250;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 10%;
  /* margin-bottom: 613px; */
  /* margin-left: 40.07%;
  margin-right: 40.14%; */
  justify-content: center;
  padding-top: 1em;
}

#try-again-validation-face {
  height: 45px;
  width: 285px;
  background-color: #e46250;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 3.5em;
  /* margin-bottom: 613px; */
  /* margin-left: 40.07%;
  margin-right: 40.14%; */
  justify-content: center;
  padding-top: 1em;
}

.validation-failed-buttons {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.validation-failed-buttons .validation-failed-button {
  height: 45px;
  width: 285px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: none;
  transition: .3s all ease;
  margin: 0;
}

.validation-failed-buttons .try-again-btn {
  background-color: #e46250;
  margin-right: 15px;
}

.validation-failed-buttons .try-again-btn:hover {
  background-color: #e1442e;
}

.validation-failed-buttons .manual-verification-btn {
  background-color: #485fc7;
  margin-left: 15px;
}

.validation-failed-buttons .manual-verification-btn:hover {
  background-color: #3e56c4;
}

.tick-container {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
}

.welcome-paragraph {
  height: 4.49%;
  /* width: 41.53%; */
  width: 93%;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  /* font-size: 16px; */
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin-bottom: 3.2%;
}

.verification-complete-paragraph {
  height: 23px;
  /* width: 436px; */
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin-bottom: 5em;
}

.google-play-store {
  height: 60px;
  width: 106.87px;
}

.account-icons {
  height: 60px;
  width: 254px;
  margin-bottom: 2em;
}

.app-store {
  height: 2.27%;
  width: 70.8%;
}

.gerty-verificate {
  height: 3.5%;
  width: 8%;
  margin-top: 8.25%;
  margin-bottom: 3%;
}

.titulo-centro-verificate {
  height: 4.69%;
  /* width: 20.49%; */
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 3em;
  display: block;
}

.titulo-centro-verificar-pendiente {
  height: 4.69%;
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 3.91%;
  display: block;
}

.titulo-verificate {
  height: 8.37%;
  width: 63.2%;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 2.5%;
}

.verificate-paragraph {
  height: 8.5%;
  width: 81.87%;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin-bottom: 2.88%;
}

.titulo-verificar-pendiente {
  height: 34px;
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 1em;
}

#continue-verificate {
  height: 45px;
  width: 285px;
  background-color: #4236ff;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  padding-top: 1em;
}

.download-app {
  height: 24px;
  width: 251px;
  color: #4236ff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.download-app-paragraph {
  height: 24px;
  width: 251px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 7px;
}

.apps-icons {
  height: 50px;
  width: 212px;
  margin-bottom: 321px;
}

.logo-google-verificate {
  height: 50px;
  width: 89.2px;
}

.gerty-code {
  margin-top: 4.93%;
  height: 41px;
  width: 139px;
  resize: contain;
}

#button-code {
  height: 45px !important;
  width: 285px !important;
  background-color: #4236ff;
}

#label-code {
  height: 22px;
  width: 53px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
}

.button-code-div .paragraph-code-div .texto-code-div .logo-code-div .figure {
  justify-content: center;
}

#circle.figure {
  align-content: center;
}

#almost-button {
  height: 45px;
  width: 285px;
  border-radius: 8px;
  background-color: #4236ff;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

#almost-label {
  height: 22px;
  width: 64px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
.no-verification {
  height: 8.37%;
  width: 77.6%;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 4%;
}

.gerty-pending {
  height: 3.5%;
  width: 7em;
  margin-top: 4em;
  margin-bottom: 2em;
  display: block;
}

.background-no-pending {
  min-height: 100vh;
  background-color: white;
  justify-items: center;
}

.no-pending-icon {
  display: block;
  padding-bottom: 2.46%;
}

.apps-icons-no-pending {
  padding-bottom: 2em;
}

#button-no-pending {
  height: 45px;
  width: 285px;
  background-color: #1b165e;
}

.label-in-no-pending-button {
  color: white !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

#button-account-ready {
  height: 45px !important;
  /* width: 285px !important; */
  width: 20em;
  border-radius: 8px !important;
  border-radius: 8px;
  background-color: #4236ff !important;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  /* margin-bottom: 135px; */
  margin-bottom: 10%;
}

.logo-appstore-verificate {
  height: 19.35px;
  width: 84.34px;
  display: inline-block;
  margin-top: 14.52px;
  margin-bottom: 16.13px;
  margin-right: 2rem;
}

.gerty-ready {
  height: 34px;
  width: 284px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  margin-bottom: 4rem;
}

.logo-codigo-listo {
  margin-top: 4em;
  height: 41px;
  width: 139px;
  resize: contain;
}

.icon-success-signup {
  height: 50px;
  width: 50px;
  margin-top: 4em;
  margin-bottom: 1.5em;
  align-content: center;
  margin-left: 0em;
}

.text-account-ready-signup {
  height: 34px;
  width: 284px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  margin-bottom: 1.6em;
}

.welcome-paragraph-signup {
  height: 4.49%;
  /* width: 41.53%; */
  width: 93%;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  /* font-size: 16px; */
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin-bottom: 3em;
}

.icon-success-almost-ready {
  height: 50px;
  width: 50px;
  margin-top: 6em;
  margin-bottom: 1.3em;
  align-content: center;
  margin-left: 0em;
}

.gerty-verificate-validar {
  width: 7em;
  margin-top: 5em;
  margin-bottom: 6em;
}

.titulo-centro-verificar-pendiente-validar {
  height: 4.69%;
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 4em;
  display: block;
}

.icon-success-verificacion-pendiente-validar {
  height: 50px;
  width: 50px;
  margin-top: 5em;
  margin-bottom: 2em;
  align-content: center;
  margin-left: 0em;
}

.indications{
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: left;
  width: max-content;

}

.please-take-it-again{
  width: max-content;
  color: #4B535A;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 3.5em;
  text-align: center;
  display: block;
}

.indications-container{
  display: block;
  text-align: center;
  width: 285px;
}

li {
  list-style-type: disc;
}

.reason-textarea {
  min-height: 75px;
}

.container-validation .capture-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.container-validation .button-switch-camera {
  width: 45px;
  height: 45px;
  margin-left: 15px;
}
.country_select__container {
  display: flex;
  width: 100%;
  max-width: 700px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}
.country_select__title {
  padding: 1%;
  z-index: 3;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  align-self: center;
  margin-bottom: 0;
  margin-top: 1.6em;
}

.container-validation .capture-buttons-container.face-capture {
  margin-top: 1em;
}
.container-validation .button-capture {
  margin-top: 0;
  margin-bottom: 0;
}
.container-validation .button-contine-capture {
  padding-top: calc(0.5em - 1px);
}
.container-validation #button-picture-face {
  height: 45px;
  padding-top: 0;
  padding-bottom: 0;
}
.container-validation .capture-buttons-container.face-capture .button-switch-camera {
  margin-bottom: 0;
}
.container-validation .span-for-emotion {
  text-transform: uppercase;
}

.container-validation .full-container-face {
  max-width: 100%;
  width: 350px;
  height: auto;
  min-height: auto;
  max-height: none;
  position: relative;
}

.container-validation .full-container-face > div {
  height: 100%;
}

.container-validation .full-container-face .borde1 {
  width: 100%;
  height: 100%;
  max-height: 350px;
  left: 0;
}

.container-validation .wide-camera-container {
  width: 500px;
  height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container-validation .almost-ready-container {
  margin-top: 4em;
}

.container-validation .rounded-face-img {
  border-radius: 50%;
  max-height: 300px;
}

.flag-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.flag-button {
  display: inline-block;
  padding: 0.8rem;
  margin: 0.8rem;
  align-items: center;
  background-color: #d9edf7;
  border-radius: 0.5rem;
}

.flag-button__selected {
    outline: 2px solid blue;
}
.flag-button__unselected {
    opacity: 0.5;
}

.flag-button__image {
  margin: 0 1rem;
  max-width: 100px;
  max-height: 3rem;
}

.flag-button__title {
  margin: 0.5rem 1rem 0;
}
.flag-button__title h4{
  margin-bottom: 0;
}

.button-img {
  width: 18rem;
  /*border: 10px;*/
  margin: 20px;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}
.button-img:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
    border: solid #b217b4;

}
.container-validation .button-automatic-photo[disabled] {
  background-color: #4236ff;
}
.bug-min-height {
  min-height: 0% !important;
}

.container-validation .container-validation__manual-validation .input-group-error input,
.container-validation .container-validation__manual-validation .input-group-error textarea {
  border-color: #dc3545;
}
.container-validation .container-validation__manual-validation .input-group-error input:focus,
.container-validation .container-validation__manual-validation .input-group-error textarea:focus {
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
}
.container-validation .container-validation__manual-validation .input-group-error .error-input-register {
  color: #dc3545;
  font-size: 80%;
}

.container-validation .field-form-register {
  margin: 2em 0;
}

.container-validation .button.is-link {
  background-color: #4236ff;
  height: 45px;
  width: 20em;
  border-radius: 8px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}

.container-validation.evaluation-details .evaluation-header {
  width: 100%;
  padding: 15px;
  justify-content: flex-end;
  display: flex;
}

.container-validation.evaluation-details .evaluation-header img {
  width: auto;
  max-width: 80px;
}

.evaluation-details.container-validation .container {
  justify-content: flex-start;
}

.evaluation-details.container-validation.evaluation-details--payment .container,
.evaluation-details.container-validation.evaluation-details--pre-payment .container:not(.with-no-evaluation) {
  padding-bottom: 231px;
  transition: all 1s ease;
}

.evaluation-details.container-validation.evaluation-details--pre-payment .container.with-no-evaluation {
  justify-content: center;
}

.evaluation-details.container-validation.evaluation-details--payment.evaluation-details__footer-closed .container,
.evaluation-details.container-validation.evaluation-details--pre-payment.evaluation-details__footer-closed .container {
  padding-bottom: 114px;
}

.evaluation-details--container {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  line-height: 1.5;
  color: #0b2355;
  max-height: 100%;
  overflow: auto;
}

.evaluation-details--container .evaluation-details--body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--header-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--header-message .evaluation-details--header-message__name {
  font-size: 22px;
  font-weight: 800;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details__provider {
  font-weight: 800;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #4236ff;
  padding-top: 10px;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details-row.evaluation-details--details__date .evaluation-details--details-col:last-child {
  flex-grow: 1;
  align-items: flex-start;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details-row:not(.evaluation-details--details__copayment) .evaluation-details--details-col:first-child,
.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details-row.evaluation-details--details__regular-price {
  color: #0b2355;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details-row.evaluation-details--details__copayment {
  font-weight: 800;
  margin-top: 10px;
  border-top: 1px solid #d3d3d3;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--gerty-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 15px;
  width: 100%;
  color: #929db3;
  font-style: italic;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--gerty-details-title {
  text-decoration: underline;
  font-size: 14px;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--gerty-details-detail {
  text-align: left;
  padding-left: 30px;
  position: relative;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details-row .evaluation-details--details-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--details-row .evaluation-details--details-col:last-child {
  width: 30%;
  align-items: flex-end;
}

.evaluation-details--container .evaluation-details--body .evaluation-details--details .evaluation-details--gerty-details-detail:before {
  content: "•";
  height: 100%;
  position: absolute;
  left: 15px;
  font-weight: 800;
}

.evaluation-details--container .evaluation-details--body.evaluation-details--body__detail .evaluation-details--details .evaluation-details--details-row {
  padding: 15px 0;
  border-top: 1px solid #d3d3d3;
}

.evaluation-details--container .evaluation-details--body.evaluation-details--body__detail .evaluation-details--details .evaluation-details--details-row:first-child {
  padding-top: 0;
  border-top: none;
}

.evaluation-details--container .evaluation-details--body.evaluation-details--body__detail .evaluation-details--details .evaluation-details--details-row.evaluation-details--details__copayment {
  margin-top: 0;
  padding-bottom: 0;
}

.evaluation-details--container .evaluation-details--body.evaluation-details--body__detail .evaluation-details--details .evaluation-details--details-row .evaluation-details--details-col .evaluation-details--details-col-title {
  font-weight: 800;
}

.evaluation-details--container .evaluation-details--body.evaluation-details--body__detail .evaluation-details--details .evaluation-details--details-row .evaluation-details--details-col .evaluation-details--details-col-message {
  text-align: left;
}

.evaluation-details--container .evaluation-details--toggle-resume {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 30px 0 30px 0;
}

.evaluation-details--container .evaluation-details--toggle-resume .evaluation-details--toggle-resume_icons {
  font-size: 16px;
}

.evaluation-details--container .evaluation-details--toggle-resume .evaluation-details--toggle-resume_message {
  font-weight: 600;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  text-decoration-color: #a9b2c1;
  padding-left: 10px;
}

.evaluation-details .evaluation-details--footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 -2px 10px 0 rgb(0 0 0 / 20%);
  height: 231px;
  transition: all 1s ease;
}

.evaluation-details.evaluation-details__footer-closed .evaluation-details--footer {
  height: 114px;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__email-input,
.evaluation-details .evaluation-details--footer .evaluation-details--footer__message {
  height: auto;
  transition: all 1s ease;
}

.evaluation-details.evaluation-details__footer-closed .evaluation-details--footer .evaluation-details--footer__email-input,
.evaluation-details.evaluation-details__footer-closed .evaluation-details--footer .evaluation-details--footer__message {
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  border: none;
  padding: 0;
}

.evaluation-details.evaluation-details__footer-closed .evaluation-details--footer .evaluation-details--footer__email-input input {
  border-radius: 0;
  border: none;
}

.evaluation-details.evaluation-details__footer-closed .evaluation-details--footer .evaluation-details--footer__email-input input:focus {
  border-color: #4236ff;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__arrow {
  position: absolute;
  top: -10px;
  right: 50%;
  transform: translateX(50%);
  border-radius: 100%;
  background: #ffffff;
  height: 25px;
  width: 25px;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__arrow svg {
  color: #4236ff;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__message {
  font-weight: 800;
  text-align: left;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__choice-container {
  padding: 5px;
  border: 1px solid #dad8fe;
  border-radius: 10px;
  background: #f6f5fd;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
  margin-bottom: 10px;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__email-input {
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__email-input input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__email-input input:focus-visible,
.evaluation-details .evaluation-details--footer .evaluation-details--footer__email-input input:focus {
  border-color: #4236ff;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__choice-container .evaluation-details--footer__choice {
  width: 50%;
  padding: 15px 0;
  cursor: pointer;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__choice-container .evaluation-details--footer__choice.evaluation-details--footer__choice-disabled {
  cursor: not-allowed;
}

.evaluation-details .evaluation-details--footer .evaluation-details--footer__choice-container .evaluation-details--footer__choice.evaluation-details--footer__choice--selected {
  border: 1px solid #dad8fe;
  border-radius: 10px;
  background: #FFFFFF;
  color: #4236ff;
  font-weight: 800;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions {
  width: 100%;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-btn {
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: 800;
  background: #4236ff;
  width: 100%;
  padding: 15px 0;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-btn.evaluation-details--actions-btn__centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-btn .evaluation-details--actions-btn_text {
  margin-left: 10px;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-error-text {
  color: #e46250;
  font-weight: 800;
}

.evaluation-status__retry-btn {
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: 800;
  background: #e46250;
  width: 100%;
  padding: 15px;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-btn.evaluation-details--actions-btn__error {
  background-color: #e46250;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-btn #circle-empty {
  margin-bottom: 0;
  height: 25px;
  width: 25px;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-btn #circle-empty .icon-loading {
  margin-top: 0;
}

.evaluation-details .evaluation-details--footer .evaluation-details--actions .evaluation-details--actions-btn.evaluation-details--actions-btn__disabled {
  cursor: not-allowed;
  background-color: #cbc8ff;
}

.evaluation-details .evaluation-details--resume {
  margin-top: 30px;
}

.evaluation-details .evaluation-details--resume .evaluation-details--resume-row {
  display: flex;
  justify-content: space-around;
  padding-bottom: 5px;
}

.evaluation-details .evaluation-details--resume .evaluation-details--resume-row .evaluation-details--resume-col {
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  width: 50%;
  color: #0b2355;
}

.evaluation-details .evaluation-details--resume .evaluation-details--resume-row .evaluation-details--resume-col-item {
  text-align: left;
}

.evaluation-details .evaluation-details--resume .evaluation-details--resume-row .evaluation-details--resume-col:first-child {
  color: #6b7a97;
}

.evaluation-details .evaluation-details--rating {
  border-top: 1px solid #d3d3d3;
  margin-top: 45px;
  padding: 15px 0 30px 0;
}

.evaluation-details .evaluation-details--rating .evaluation-details--rating-message {
  text-align: left;
  margin-bottom: 5px;
}

.evaluation-details .evaluation-details--rating .evaluation-details--rating-thanks {
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: #4236ff;
}

.container-validation .verification-complete__redirect-message {
  padding-bottom: 1.5rem;
  color: #4236ff;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.container-validation .verification-complete__redirect-message img {
  margin-top: 0;
  width: 35px;
}
.container-validation .verification-complete__redirect-message span {
  padding-top: 15px;
}

.sign_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-bottom: 1rem;
}

.signature-canvas {
  border: 1px solid #000;
  max-width: 620px;
  max-height: 30vh;
  aspect-ratio: 1.61803398874989;
  width: auto;
}

.terms-checkbox {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  max-width: 70vw;
  width: 100%;
}

.terms-checkbox__input {
  margin-right: 0.5rem;
  cursor: pointer;
}

.terms-checkbox__text {
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
}

.terms-checkbox__text a {
  color: #4236ff;
  text-decoration: none;
}

.terms-checkbox__text a:hover {
  text-decoration: underline;
}
  
.warning-icon {
  font-size: 1.5rem;
  color:#4c4226
}

.flash-warning {
  color: #000;
  font-weight: 900;
  text-align: start;
}  

/* Mobile */
@media (max-width: 639px){

  .container-validation .rounded-face-img {
    height: 325px;
  }

  .container-validation .almost-ready-container {
    margin-top: 0;
  }

  .captured.captured__carnet {
    margin-top: 0;
  }
  .container-validation .wide-camera-container {
    width: 360px;
    height: 270px;
  }
  .container-validation .full-container-of-cam3,
  .container-validation .wide-camera-container {
    width: 100%;
  }
  .container-validation .button-switch-camera {
    margin-left: 0;
  }
  .container-validation .capture-buttons-container {
    justify-content: space-around;
  }
  .container-validation {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .container-validation .container {
    max-width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .container-validation .titulo-verificar-pendiente,
  .container-validation .titulo-centro-verificar-pendiente-validar,
  .container-validation .verification-complete-paragraph {
    width: auto;
    height: auto;
  }
  .container-validation .verification-complete-paragraph {
    margin-bottom: 2em;
  }
  .container-validation #circle-empty {
    margin-top: auto;
    margin-bottom: auto;
  }
  .container-validation .button-capture,
  .container-validation .button-again-capture,
  .container-validation .button-contine-capture,
  .container-validation #almost-button,
  .container-validation #button-picture-face,
  .container-validation form button,
  .container-validation #button-signin {
    height: 45px;
    width: 20em;
    border-radius: 8px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .container-validation .button-again-capture {
    color: #4236ff;
  }

  .container-validation .button-capture,
  .container-validation .button-contine-capture,
  .container-validation #almost-button,
  .container-validation #button-picture-face,
  .container-validation form button,
  .container-validation form button.button.is-link,
  .container-validation #button-signin {
    background-color: #4236ff;
  }
  .container-validation .title-capture {
    margin-top: 15px;
  }
  .container-validation .icon-error {
    margin-top: 0;
  }
  .container-validation .captured.captured__carnet {
    flex-direction: column;
    margin-top: 15px;
    padding-top: 0;
    width: 100%;
  }
  .container-validation .captured.captured__carnet .slider-container {
    width: 100%;
  }
  .container-validation .validation-failed-buttons {
    justify-content: space-around;
  }
  .container-validation .validation-failed-button {
    width: 48%;
    max-width: 285px;
    margin-right: 0;
    margin-left: 0;
  }
  .container-validation .field-form-register {
    width: 100%;
    height: auto;
  }
  .container-validation .container-validation__manual-validation {
    padding-top: 0;
  }
  .container-validation .title-capture,
  .container-validation .subtitle-capture {
    position: relative;
    width: auto;
    margin-bottom: 1em;
  }
  .container-validation .img-face-circle {
    margin-top: calc(12em - 10px);
  }
  .container-validation .capture .button-again-capture.face-btn {
    margin-top: 15px;
  }
  .container-validation .captured {
    width: 100%;
  }
  .container-validation .icon-success-verificacion-pendiente {
    margin-top: 0;
  }
  .container-validation .background-capture .title-capture,
  .container-validation .background-capture .subtitle-capture {
    margin-bottom: 0;
  }
  .container-validation .please-take-it-again {
    margin-bottom: 2em;
  }
  .container-validation .indications-container {
    width: calc(100% - 30px);
  }
  .container-validation .indications {
    width: 100%;
    text-align: justify;
  }
  .container-validation .container-validation__manual-validation .control__indicator-text {
    line-height: 1.5;
    font-size: 16px;
  }
  .flag-button {
    display: flex;
    margin: 0.4rem;
    padding: 0.7rem;
  }
  .flag-button__title{
    margin-top: 0;
  }
  .flag-button__image {
    max-height: 1.5rem;
    margin: 0;
  }
  .button-img {
    width: 40vw;
  }
  .button-img:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }

  .signature-canvas {
    max-width: 95vw;
  }
}

/* Small devices */
@media (max-width:376px) {
  body {
    min-height: auto;
  }
  .container-validation .button-capture,
  .container-validation .button-again-capture,
  .container-validation .button-contine-capture,
  .container-validation #almost-button,
  .container-validation #button-picture-face,
  .container-validation form button,
  .container-validation #button-signin {
    width: 13em;
  }
  .container-validation .state-info,
  .container-validation .titulo-centro-verificar-pendiente-validar,
  .container-validation .verification-complete-paragraph,
  .container-validation .please-take-it-again,
  .container-validation__manual-validation {
    font-size: 14px;
  }
  .container-validation .indications {
    font-size: 13px;
  }
  .container-validation .validation-failed-buttons .validation-failed-button {
    font-size: 12px;
  }
  .container-validation .titulo-verificar-pendiente,
  .container-validation .title-capture,
  .container-validation .text-complete-verification,
  .container-validation .text-failed-verification,
  .container-validation .verifying-h1,
  .container-validation .titulo-register {
    font-size: 18px;
    line-height: 25px;
  }
  .container-validation .subtitle-capture {
    font-size: 16px;
  }
  .container-validation .gerty-verificate-validar {
    width: 6em;
  }
  .container-validation .wide-camera-container {
    height: 200px;
  }
  .container-validation .captured.captured__carnet .slider-container .container-slider {
    width: 100%;
    height: 200px;
  }
  .container-validation .button-img {
    margin: 10px;
  }
  .container-validation .verification-complete-paragraph {
    font-size: 14px;
  }
  .container-validation .icon-success,
  .container-validation .icon-success-verificacion-pendiente,
  .container-validation .icon-error,
  .container-validation .button-switch-camera {
    width: 35px;
    height: 35px;
  }
  .container-validation .rounded-face-img {
    max-height: 256px;
    width: auto;
  }
  .container-validation .container-validation__manual-validation .button.is-link {
    width: 15em;
  }
  .container-validation .container-validation__manual-validation .control__indicator-text {
    font-size: 12px;
  }
}