.container-background-view-verification{
  background-color: #f4f4f5;
  height: 100%;
}
.container-all-table-view-verification {
  height: 100%;
  overflow: auto;
}
.container-up-info-table-header-view-verification{
  font-family : 'Open Sans', sans-serif;;
  line-height: 41px;
  color: #4A4A4A;
  font-weight: bold;
  font-size: 35px;
  display: flex;
  letter-spacing: -0.2px;
  width: 1340px;
  text-align: right;
  vertical-align: middle;
}
.container-up-info-table-header-view-verification h4{
  height: 19px;
  width: 391px;
  color: #9B9B9B;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
  margin-top: 9px;
}
.container-up-info-table-header-view-verification h1{
  font-family : 'Open Sans', sans-serif;;
  line-height: 41px;
  color: #4A4A4A;
  font-weight: bold;
  font-size: 35px;
  display: flex;
  letter-spacing: -0.2px;
  width: 1040px;
  text-align: right;
  vertical-align: middle;
}
.container-up-info-table-view-verification{
  width: 1440px;
  margin-top: 50px;
  display: inline-flex;
  text-align: center;
  margin-bottom: 30px;
}
.container-verification-info{
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.container-verification-info-header{
  background-color: #F8FBFF;
  border-bottom: 1px solid #ced4da;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 30px;
}
.container-verification-info-header h1{
  color: #4B535A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 24px;
  letter-spacing: -0.02px;
  line-height: 33px;
  vertical-align: middle;
}
.container-verification-info-header h5{
  color: #4B535A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 33px;
  vertical-align: middle;
  text-align: left;
}
.container-verification-info-header h3{
  color: #4A4A4A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0;
  margin-left: 15px;
}
.container-verification-info-header h6{
  color: #4B535A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.02px;
  line-height: 27px;
}
.container-verification-info-header h2{
  color: #4236FF;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left;
}
.container-verification-info-header h1,
.container-verification-info-header h2,
.container-verification-info-header h3,
.container-verification-info-header h4,
.container-verification-info-header h5,
.container-verification-info-header h6 {
  margin-bottom: 0;
  margin-right: 15px;
  text-align: left;
  margin-left: 0;
}
.container-right-accuracy{
  color: #4A4A4A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: left;
}
#circle-verification-accuracy {
  height: 11px;
  width: 11px;
  background-color: #E8C35D;
  border-radius: 25px;
  margin-top: 4px;
}
#circle-verification-accuracy-red {
  height: 11px;
  width: 11px;
  background-color: #EF7474;
  border-radius: 25px;
  margin-top: 4px;
}
.container-right-accuracy h4{
  height: 21px;
  width: 236px;
  color: #4A4A4A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 8px;
  font-weight: bold;
  display: flex;
  margin-bottom: 0;
}
.container-circle-text{
  display: flex;
  align-items: center;
}
.container-circle-text:not(:last-child) {
  margin-bottom: 5px;
}
.container-verification-info-sub-header-container {
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: flex;
}
.container-verification-info-sub-header{
  background-color: #ffffff;
  min-height: 100px;
  text-align:center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  max-width: 600px;
  flex-wrap: wrap;
  height: inherit;
  flex-grow: 1;
}
.container-verification-info-sub-header .meter {
  margin-top: 0;
}
.container-verification-info-sub-header h1{
  color: #4B535A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 33px;
  margin-bottom: 0;
}
.container-verification-info-sub-header h2{
  color: #4A4A4A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0;
  text-align: left;
}
.container-status-bar-verification{
  display: inline-block;
  text-align: center;
}
.container-verification-info-down {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.container-verification-info-down-left{
  background-color: #FFFFFF;
  width: 100%;
  border-bottom: 1px solid #ced4da;
  display: block;
  text-align: center;
}
.container-verification-info-down-left h1 {
  color: #4B535A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 33px;
  margin-top: 30px;
  margin-left: 30px;
  text-align: left;
}
.container-verification-info-down-left-detail {
  display: block;
  text-align: center;
  margin-top: 15px;
  margin-left: 30px;
}
.container-verification-info-down-left-detail h3{
  color: #4A4A4A;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
}
.container-verification-info-down-right{
  background-color: #FFFFFF;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* Laptops / Desktops */
  .container-verification-info-down {
    flex-grow: 1;
    flex-direction: row;
    overflow: hidden;
  }
  .container-verification-info-down-left,
  .container-verification-info-down-right {
    height: 100%;
    overflow: auto;
  }
  .container-verification-info-down-left{
    border-right: 1px solid #ced4da;
    border-bottom: none;
    width: 30%;
  }
  .container-verification-info-down-right{
    width: 70%;
  }
  .container-verification-info-sub-header {
    height: auto;
  }
}

.checkmark {
  display:inline-block;
  width: 22px;
  height:22px;
  transform: rotate(45deg);
}
.flower__head {
  display:inline-block;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  margin-top: 50px;
}

.flower__head::after, .flower__head::before {
  content: '';
  border-radius: 20px;
  width: 120px;
  height: 40px;
  transform: rotate(60deg);
}

.flower__head::before {
  transform: rotate(-60deg);
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#ccc;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#ccc;
    left:8px;
    top:12px;
}


.checkmark {
  width: 15px;
  height:15px;
  background-color: #4236FF;
  border-radius: 40px;
}

.checkmark_stem {
  width:2px;
  height:8px;
  background-color:#ccc;
  margin-top: -3.5px;
  margin-left: -4.5px;

}

.checkmark_kick {
  width:3px;
  height:2px;
  background-color:#ccc;
  margin-top: -3.5px;
  margin-left: -4.5px;
}




.meter-view {
  box-sizing: content-box;
  height: 12px; /* Can be anything */
  position: relative;
  background: #d8d8dd;
  width: 65px;
  border-radius: 25px;
  padding: 1px;
  margin-top: 40px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter-view > span {
  display: block;
  height: 100%;
  width: 25%;
  border-radius: 25px;
    border: 1px solid black;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
.meter-view > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.orange > span {
  background-image: linear-gradient(#f1a165, #f36d0a);
}

.red > span {
  background-image: linear-gradient(#f0a3a3, #f42323);
}