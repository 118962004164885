.gerty-info-rut {
  width: 7em;
  margin-bottom: 2em;
}

.verifying-h1 {
  height: 34px;
  width: max-content;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
}

@media (max-device-width: 376px) {
  .verifying-h1 {
    font-size: 18px;
  }
}

.verifying-loading {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.alert-info-rut {
  margin-top: 4em;
}

@media (max-width: 639px){
  .alert-info-rut {
    margin-top: 0;
  }
}

.alert-info-rut .icon-loading {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 40px;
}