.download-img-modal {
  font-family: 'Nunito Sans', sans-serif;
  color: #4A4A4A;
}

.download-img-modal .modal-header {
  display: flex;
  padding: 21px 30px;
}

.download-img-modal .modal-header .modal-title {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding-right: 15px;
}

.download-img-modal .modal-header .modal-title .download-img-modal__download_btn {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12.5px 22px;
  color: #4236FF;
  border: 1px solid #4236FF;
  border-radius: 6px;
  background: #ffffff;
  transition: .5s ease all;
}

.download-img-modal .modal-header .modal-title .download-img-modal__download_btn:hover {
  background: #4236FF;
  color: #ffffff;
}

.download-img-modal .modal-header .modal-title .modal-title-section .modal-title-section__title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.download-img-modal .modal-header .modal-title .modal-title-section .modal-title-section__subtitle {
  color: #9E9E9E;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}

.download-img-modal .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}