.background-form-login {
  display: block;
  justify-content: center;
  align-items: center;
  min-height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #4236ff;
  padding-bottom: 1%;
  height: 100vh;
  width: 100vw;
  position:fixed;
  top:0;
  left:0;
  overflow: auto;
}


.gerty-white {
  margin-top: 8%;
  height: 37.8px;
  width: 130.68px;
  resize: contain;
  /* margin-left: 1em; */
  margin-bottom: 20.2px;
}

#nuevo-en-gerty {
  height: 15px;
  width: 20em;
  opacity: 0.8;
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14.7px;
  text-align: center;
  margin-bottom: 45px;
}

#span-login {
  height: 15px;
  /* width: 200px; */
  opacity: 0.8;
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 14.7px;
  text-align: center;
  margin-bottom: 45px;
}

.titulo {
  height: 4.19%;
  width: 44.53%;
  margin-right: 15.73%;
  margin-left: 15.73%;
  margin-bottom: 37px;
  color: #333333;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 34px;
  text-align: center;
  margin-top: 0% !important;
}

.field-login {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}


.label-for-email {
  height: 19px;
  width: 54px;
  color: #4a4a4a;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  float: left;
}

.input-login-email {
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
  padding-left: 2%;
  height: 3.2em;
  width: 20em;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.input-login-email::placeholder {
  height: 16px;
  width: 35px;
  color: #9e9e9e;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.input-login-password {
  font-family: 'Nunito Sans', sans-serif;

  box-sizing: border-box;
  height: 3.2em;
  padding-left: 2%;
  width: 20em;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: #ffffff;
  justify-content: center;
  display: inline;
}

.input-login-password::placeholder {
  height: 16px;
  width: 73px;
  color: #9e9e9e;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

#forgot-password {
  height: 15px;
  width: 155px;
  opacity: 0.8;
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14.7px;
  text-align: center;
  margin-bottom: 35px;
}

#o {
  height: 15px;
  opacity: 0.7;
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14.7px;
  text-align: center;
  /* margin-bottom: 31px; */
  margin-bottom: 3%;
}

#button-signin {
  height: 45px;
  width: 20em;
  border-radius: 8px;
  background-color: #1b165e;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 15px;
}

.container-validation #button-signin {
  background-color: #4236ff;
}

#button-final-verification {
  height: 45px;
  width: 20em;
  border-radius: 8px;
  background-color: #4236ff;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  /* margin-bottom: 135px; */
  margin-bottom: 10%;
}

#button-signin:disabled {
  background-color: #1b165e;
  opacity: 0.7;
}

#button-signin-option-google {
  height: 45px;
  width: 20em;
  border-radius: 8px;
  display: inline-block;
  border-radius: 8px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 15px;
  /* padding-bottom: auto; */
}
#button-signin-option-facebook {
  height: 45px;
  width: 20em;
  border-radius: 8px;
  display: inline-block;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 15px;
  /* padding-bottom: auto; */
}

#button-signin-option-apple {
  height: 45px;
  width: 20em;
  border-radius: 8px;
  display: inline-block;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 5%;
}

.button-options-container {
  height: max-content;
  width: 20em;
}

#label-signin-option {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.logo-button-register-view-google {
  height: 18px;
  width: 17px;
  vertical-align: center;
  margin-right: 4%;
  margin-left: 4%;
  padding-top: 1%;
}

.logo-button-register-view-facebook {
  height: 20px;
  width: 17px;
  vertical-align: center;
  margin-left: 11%;
  margin-right: 4%;
  padding-top: 1%;
  padding-left: 0.5%;
}

.logo-button-register-view-apple {
  height: 21px;
  width: 17px;
  vertical-align: center;
  margin-right: 4%;
  padding-top: 1%;
}

#image-and-label {
  text-align: center;
  vertical-align: center;
}

#arrow-icon-eye{
  position: absolute;
  justify-content: right;
  display: inline-block;
  vertical-align: middle;
  margin-top: 0.8em;
  margin-left: 290px;
  color: #4236FF;
  cursor: pointer;
}

.input-icons{
  width: 100%;
}