.state-info {
  top: 0px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  z-index: 999;
}

.state-info.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.state-info.alert-info strong {
  color: #31708f;
}

.state-info.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.state-info.alert-danger strong {
  color: #a94442;
}

.state-info.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.state-info.alert-success strong {
  color: #3c763d;
}