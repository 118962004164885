.new-location-modal {
  font-family: 'Nunito Sans', sans-serif;
  color: #4A4A4A;
  padding-right: 0 !important;
}

.new-location-modal .modal-header {
  display: flex;
  padding: 21px 30px;
}

.new-location-modal .modal-header .modal-title {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding-right: 15px;
}

.new-location-modal .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

.new-location-modal .modal-body .new-location-modal__new_form {
  display: flex;
  flex-direction: column;
}

.new-location-modal .modal-body .new-location-modal__new_form .new-location-modal__error {
  color: #df4759;
  font-size: 14px;
}

.new-location-modal .modal-body .new-location-modal__new_form.input-has-error .new-location-modal__new_form_body .new-location-modal__edit_input  {
  border-color: #df4759;
}

.new-location-modal .modal-body .new-location-modal__new_form .new-location-modal__new_form_body {
  display: flex;
  align-items: center;
}

.new-location-modal .modal-body .new-location-modal__new_form .new-location-modal__new_form_body .new-location-modal__edit_input {
  font-size: 16px;
  border-radius: 6px;
  width: 200px;
  height: 40px;
  padding: 5px;
  border: 1px solid #DDDDDD;
}

.new-location-modal .modal-body .new-location-modal__new_form .new-location-modal__new_form_body .new-location-modal__confirm_btn {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 11px 22px;
  border: 1px solid #4236FF;
  border-radius: 6px;
  transition: .5s ease all;
  margin-left: 5px;
  width: 100px;
  background: #4236FF;
  color: #ffffff;
}

.new-location-modal .modal-body .new-location-modal__new_form .new-location-modal__new_form_body .new-location-modal__confirm_btn:hover {
  background: #ffffff;
  color: #4236FF;
}